import React, { lazy } from "react";
import { Redirect } from "react-router-dom";


export const routes = [

  {
    exact: true,
    path: "/",
    component: lazy(() => import("src/views/pages/Home/landingpage")),
  },

  {
    exact: true,
    path: "/privacy",
    component: lazy(() => import("src/component/PrivacyPolicy")),
  },
  {
    exact: true,
    path: "/term",
    component: lazy(() => import("src/component/TermCondition")),
  },
  {
    exact: true,
    path: "/aboutus",
    component: lazy(() => import("src/component/AbouteUs")),
  },
  // {
  //   exact: true,
  //   path: "/abouteus",
  //   component: lazy(() => import("src/component/AbouteUs")),
  // },

  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
