import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import { calculateTimeLeft } from "src/utils";
import { getDataHandlerWithToken } from "src/apiconfig/service";
import { getDataHandlerAPI } from "src/apiconfig/service";
import ApiConfig from "src/apiconfig/apiconfig";
import io from "socket.io-client";
import { toast } from 'react-hot-toast';
export const AuthContext = createContext();

const setSession = (accessToken) => {
  if (accessToken) {
    sessionStorage.setItem("creatturAccessToken", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    sessionStorage.removeItem("creatturAccessToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

function checkLogin(token) {
  const accessToken = window.sessionStorage.getItem("token")
    ? window.sessionStorage.getItem("token")
    : token;
  return accessToken ? true : false;
}



export default function AuthProvider(props) {
  const [isLogin, setIsLogin] = useState(checkLogin());
  const [userData, setUserData] = useState({});
  const [endTime, setEndtime] = useState();
  const [multibotStart, setmultibotStart] = useState(false);
  const [timeLeft, setTimeLeft] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [connectedExchangeList, setConnectedExchangeList] = useState();
  const [tokenDetail, setTokenDetail] = useState([]);
  const [updateCountData, setUpdateCountData] =useState([]);
  

  const getProfileData = async () => {
    try {
      const res = await getDataHandlerWithToken("profile");
      if (res.result) {
        setUserData(res.result);
       
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setIsLogin(false);
      }
    } catch (error) {
      setIsLoading(false);
      setIsLogin(false);
    }
  };

  useEffect(() => {
    if (sessionStorage.getItem("token")) getProfileData();
  }, [sessionStorage.getItem("token")]);

  const getConnectedExchangeList = async (token) => {
    try {
      const response = await getDataHandlerAPI("connectedExchangeList", token);
      if (response) {
        setConnectedExchangeList(response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (window.sessionStorage.getItem("token")) {
      // getUserProfileDatahandler(window.sessionStorage.getItem("token"));
      getConnectedExchangeList(window.sessionStorage.getItem("token"));
    }
  }, [window.sessionStorage.getItem("token")]);

  const getData = async () => {
    try {
      const res = await axios({
        method: "POST",
        url: ApiConfig.tokenDeatils,
        data:{
          user_id:userData._id
        }
      });

      if (res.status == 200) {
        setTokenDetail(res?.data[0]);
        sessionStorage.setItem("WalletDetails", JSON.stringify(res?.data));
        setmultibotStart(!multibotStart);
        // console.log(res?.data[0],"our response")
      
      }
    } catch (error) {
      // toast.error("Something went Wrong");
    }
  };

  useEffect(() => {
    if(userData._id){
      getData();
    }
    else{
     
    }
   
  },[userData._id]);
  console.log("our wallet address",tokenDetail?.walletAddress)

  useEffect(() => {
    if (endTime) {
      const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft(endTime));
      }, 1000);
      return () => clearTimeout(timer);
    }
  });

  // const socket = io(ApiConfig.botSok);
  // useEffect(() => {
  //   // Handle connection and messages when the component mounts
  //   socket.on("connect", () => {
  //     console.log("Connected to WebSocket server UpdateCount");
  //   });
 
  //   socket.on("UpdateCount", (message) => {
  //     console.log(message,"our message")
  //     // When a message is received, process it and update the component's state
  //     setUpdateCountData((prevMessages) => [message, ...prevMessages]);
  //   });
    
  //   socket.on("disconnect", () => {
  //     console.log("Disconnected from WebSocket server");
  //   });

  //   // Clean up the socket when the component unmounts
  //   return () => {
  //     socket.disconnect();
  //   };
  // }, []);

// const tradeCountNum=updateCountData?.tradeCount

//   const postUpdateData=async(values)=>{
//     try{
//       const res = await axios({
//         method:'POST',
//         url:ApiConfig.updateSniperTradeCount,
//         data :{
//           userId:userData._id,
//           walletAddressData: [
//             {
//               walletAddress:tokenDetail?.walletAddress ? tokenDetail?.walletAddress : "--",
//               tradeCount: tradeCountNum
//             }
//           ]
//         }
//       });
//       console.log("resp===>", res)
//         if(res.responseCode === 200){
//               return res.data;
//         }else{
//               return res
//         }
//       }catch(error){
//          console.log(error)
//     }
//   }

//   useEffect(()=>{
//     postUpdateData()
//   },[tradeCountNum])







  let data = {
    userLoggedIn: isLogin,
    userData,
    setEndtime,
    isLoading,
    timeLeft,
    tokenDetail,
    connectedExchangeList,
    multibotStart,
    getData: () => {
      getData();
    },
    userLogIn: (type, data) => {
      setSession(data);
      setIsLogin(type);
    },
    setIsLogin,
    getProfileData: () => {
      getProfileData();
    },
    checkLogin: (token) => {
      checkLogin(token);
    },
  };

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
